import React from 'react';

import { theme } from '@cameo/theme';

type Props = {
  size?: number;
};

// TODO: Move to domains/web/Icon component once built
export const Lightning = ({ size = 24 }: Props) => {
  return (
    <svg
      width={size}
      height={size + 1} // required to accommodate the SVG uneven size
      viewBox={`0 0 ${size} ${size + 1}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1L1 7H5.5L5 11L10 5H5.5L6 1Z"
        fill={theme.colors['yellow-60']}
        stroke={theme.colors['yellow-60']}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
