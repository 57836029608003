import React from 'react';
import track from 'react-tracking';
import { TileImpression } from '@cameo/events';

export type TileImpressionContext = Partial<TileImpression>;

type contextDataProps<P> =
  | TileImpressionContext
  | ((props: P) => TileImpressionContext);

export function withImpressionContext<P>(
  contextData: contextDataProps<P>
): (wrappedComponent: React.ComponentType<P>) => React.ComponentType<P> {
  return (wrappedComponent: React.ComponentType<P>) =>
    track(contextData)(wrappedComponent);
}
