import { TFunction } from 'i18next';

type Args = {
  price: number;
  locale: string;
  opts: {
    currency: string;
    currencyRate: number;
    t: TFunction;
  };
};

export const getFormattedPrice = ({ price, locale, opts }: Args) => {
  if (price === 0) {
    return opts.t('price.free', 'Free');
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: opts?.currency?.toUpperCase() ?? 'USD',
    maximumFractionDigits: 0,
  }).format(price * (opts?.currencyRate ?? 1));
};
