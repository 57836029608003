import React from 'react';

import { theme } from '@cameo/theme';

type Props = {
  size?: number;
};

// TODO: Move to domains/web/Icon component once built
export const Star = ({ size = 24 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Star"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03274 3.39007L6.49303 3.33599L5.65875 0.823207C5.29424 -0.275096 4.7034 -0.273709 4.34021 0.823207L3.50724 3.33599L0.967535 3.39007C-0.143091 3.41365 -0.32337 4.00163 0.56092 4.70193L2.58347 6.31056L1.84788 8.85662C1.5268 9.96879 2.00447 10.3321 2.91508 9.66787L4.99948 8.148L7.08519 9.66787C7.9958 10.3321 8.47347 9.96741 8.15239 8.85662L7.41548 6.31056L9.43935 4.70332C10.325 4.00163 10.1407 3.41365 9.03274 3.39007Z"
        fill={theme.colors['yellow-60']}
      />
    </svg>
  );
};
