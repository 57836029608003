import { StarCardUserFields } from 'types/src/api/models/user';
import { getActiveUserPromotion } from 'utils/userPromotion';
import { shouldDisplayRemainingUses } from './promotions';

const TAG_TEMPORARILY_UNAVAILABLE = 'temporarily-unavailable';
const TAG_QUANTITY_LEFT = 'quantity-left';

export function isTalentExpeditedEnabled(user: StarCardUserFields) {
  return user.talentSettings?.expeditedCameoSettings?.expeditedCameosEnabled;
}

export function generateTalentImpressionTags(user: StarCardUserFields) {
  const tags = user.tags?.map((tag) => tag.slug) ?? [];

  if (user.temporarilyUnavailable) {
    tags.push(TAG_TEMPORARILY_UNAVAILABLE);
  }

  const activeUserPromotion = getActiveUserPromotion(user);
  if (shouldDisplayRemainingUses(activeUserPromotion)) {
    tags.push(TAG_QUANTITY_LEFT);
  }

  return tags;
}

export enum BadgeType {
  'BUSINESS_ONLY' = 'BUSINESS_ONLY',
  'CHARITY' = 'CHARITY',
  'DEAL' = 'DEAL',
  'LIVE' = 'LIVE',
  'TEMPORARILY_UNAVAILABLE' = 'TEMPORARILY_UNAVAILABLE',
  'UPCOMING_LIVE_CALL' = 'UPCOMING_LIVE_CALL',
  'UPCOMING_LIVE_EVENT' = 'UPCOMING_LIVE_EVENT',
  'PRIVATE_CAMEO_LIVE_EVENT' = 'PRIVATE_CAMEO_LIVE_EVENT',
  'DMS_ONLY' = 'DMS_ONLY',
}

// This is the priority order for which badge a card will display.
const BADGE_PRIORITIES = [
  BadgeType.LIVE,
  BadgeType.UPCOMING_LIVE_CALL,
  BadgeType.DEAL,
  BadgeType.CHARITY,
  BadgeType.BUSINESS_ONLY,
  BadgeType.PRIVATE_CAMEO_LIVE_EVENT,
  BadgeType.DMS_ONLY,
  BadgeType.TEMPORARILY_UNAVAILABLE,
];
const MAX_BADGES = 1;

/**
 * This function handles the rules logic for our various badges.
 * Please note - this function only checks to see if a user is _eligible_ for a badge.
 * Final badge visibility will be based on the priority order specified in BADGE_PRIORITIES.
 * Please do not add any prioritization logic here.
 */
export const checkBadgeEligibility = (
  badgeType: BadgeType,
  user: StarCardUserFields,
  tagSlugs: Set<string>,
  hasUserActivePromotion: boolean,
  hasRemainingUses: boolean,
  isTalentTeamMainCharityEnabled: boolean
) => {
  if (badgeType === BadgeType.BUSINESS_ONLY) {
    return user.temporarilyUnavailable && !!user.businessPrice;
  }
  if (badgeType === BadgeType.CHARITY) {
    const charityId =
      user.talentSettings?.charitySettings?.campaign?.charityId ||
      (isTalentTeamMainCharityEnabled &&
        user.talentSettings?.charitySettings?.charityId);
    return !!charityId && !hasUserActivePromotion;
  }
  if (badgeType === BadgeType.DEAL) {
    return (
      hasUserActivePromotion &&
      !user.temporarilyUnavailable &&
      !hasRemainingUses
    );
  }
  if (badgeType === BadgeType.DMS_ONLY) {
    return (
      user.temporarilyUnavailable && user.talentSettings.availableForPaidDM
    );
  }
  if (badgeType === BadgeType.TEMPORARILY_UNAVAILABLE) {
    return user.temporarilyUnavailable;
  }
  return false;
};

export function getBadgesToDisplay(
  user: StarCardUserFields,
  hasUserActivePromotion: boolean,
  hasRemainingUses: boolean,
  isTalentTeamMainCharity: boolean
) {
  const tagSlugs = new Set((user?.tags || []).map((tag) => tag.slug));

  const selectedBadges: { [key in BadgeType]?: boolean } = {};
  let badgesSelected = 0;
  BADGE_PRIORITIES.forEach((badgeType) => {
    const shouldDisplayBadge =
      badgesSelected < MAX_BADGES &&
      checkBadgeEligibility(
        badgeType,
        user,
        tagSlugs,
        hasUserActivePromotion,
        hasRemainingUses,
        isTalentTeamMainCharity
      );

    if (shouldDisplayBadge) {
      badgesSelected += 1;
    }

    selectedBadges[badgeType] = shouldDisplayBadge;
  });

  return selectedBadges;
}
