import { useSelector } from 'react-redux';
import React from 'react';

// TODO: should be handled by the `StarCard` component, but we are sharing the logic
// with the C4B component
import * as analytics from 'domains/consumer/components/StarCard/analytics';

import { withImpressionTracker } from 'analytics/components/withImpressionTracker';
import { TileImpressionContext } from 'analytics/components/withImpressionContext';
import { selectors as clientInfoSelectors } from 'state/modules/clientInfo/i18n';
import { StarCard } from 'domains/consumer/components/StarCard';
import { useStarCardOverrides } from 'domains/c4b/shared/consumer/components/StarCardOverridesProvider/hooks/useStarCardOverridesProvider';
import { generateTalentImpressionTags } from './utils/badges';
import { getDisplayPrice } from './utils/price';
import { StarCardProps } from './types';

const StarCardWrapper = (props: StarCardProps) => {
  const internationalInfo = useSelector(
    clientInfoSelectors.getInternationalInfo
  );

  /**
   * <C4B StarCard code>
   */
  const { CustomCardComponent } = useStarCardOverrides();
  if (CustomCardComponent) {
    return (
      <CustomCardComponent
        user={props.user}
        onClick={
          props.onClick as (event: React.MouseEvent<HTMLAnchorElement>) => void
        }
        onMouseEnter={() =>
          analytics.onEvent({
            data: props.user,
            eventType: 'Hover',
          })
        }
      />
    );
  }
  /**
   * </C4B StarCard code>
   */

  return (
    <StarCard
      locale={internationalInfo}
      data={props.user}
      onMouseEnter={() =>
        analytics.onEvent({
          data: props.user,
          eventType: 'Hover',
        })
      }
      onClick={
        props.onClick as (event: React.MouseEvent<HTMLAnchorElement>) => void
      }
      key={props.user._id}
    />
  );
};

const withImpressionTrackerStarCard = withImpressionTracker(
  StarCardWrapper,
  ({ user }: StarCardProps): TileImpressionContext => {
    return {
      talentDisplayCameoPrice: getDisplayPrice(user),
      talentDisplayChatPrice: user.dmPrice,
      talentOriginalCameoPrice: user.price,
      talentOriginalChatPrice: user.dmPrice,
      tileId: user._id,
      tileReadableName: user.name,
      tileType: 'talent',
      talentTags: generateTalentImpressionTags(user),
    };
  }
);

export default React.memo(withImpressionTrackerStarCard);
