import { UserPromotion } from 'types/src/api/models/user';
import { isUsableUserPromotion } from 'utils/userPromotion';

const MAX_USES_TO_DISPLAY = 10;

export function shouldDisplayRemainingUses(activeUserPromotion: UserPromotion) {
  if (activeUserPromotion && isUsableUserPromotion(activeUserPromotion)) {
    const { usesRemaining } = activeUserPromotion;
    if (usesRemaining > 0 && usesRemaining <= MAX_USES_TO_DISPLAY) {
      return true;
    }
  }

  return false;
}

export function getRemainingPromotionUses(activeUserPromotion: UserPromotion) {
  return activeUserPromotion?.usesRemaining ?? 0;
}
