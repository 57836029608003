import isNil from 'lodash/isNil';
import { TFunction } from 'i18next';
import { StarCardUserFields } from 'types/src/api/models/user';
import { RegionPreferences } from 'server/middlewares/clientInfoMiddleware';
import {
  getActiveUserPromotion,
  getUserPromotionDiscountPrice,
  isUsableUserPromotion,
} from 'utils/userPromotion';
import { getPrice } from 'design-system/Components/Price/util';

export function getDiscountedPrice(
  user: StarCardUserFields,
  activeUserPromotion
) {
  if (isUsableUserPromotion(activeUserPromotion)) {
    return getUserPromotionDiscountPrice(user.price, activeUserPromotion);
  }

  return null;
}

export function getDisplayPrice(user: StarCardUserFields) {
  const activeUserPromotion = getActiveUserPromotion(user);
  const discountedPrice = getDiscountedPrice(user, activeUserPromotion);
  return discountedPrice ?? user.price;
}

export function getPricesToDisplay(
  price: StarCardUserFields['price'],
  businessPrice: StarCardUserFields['businessPrice'],
  discountedPrice: number,
  isBusinessStarcard: boolean,
  activeUserPromotionExists: boolean,
  internationalInfo: RegionPreferences,
  t: TFunction
) {
  const normalizedBusinessPrice = Number.isNaN(businessPrice)
    ? 0
    : businessPrice;
  const normalizedDiscountPrice = Number.isNaN(discountedPrice)
    ? 0
    : discountedPrice;
  const normalizedPrice = Number.isNaN(price) ? 0 : price;

  let basePrice;
  let baseDiscountedPrice;

  if (isBusinessStarcard) {
    basePrice = normalizedBusinessPrice || normalizedPrice;
  }

  if (!isBusinessStarcard) {
    basePrice = normalizedPrice;
  }

  if (!isBusinessStarcard && !isNil(discountedPrice)) {
    basePrice = normalizedDiscountPrice;
    baseDiscountedPrice = normalizedPrice;
  }

  const priceToDisplay =
    basePrice === 0 && isNil(discountedPrice)
      ? t('pricing.free', 'Free')
      : getPrice(basePrice, internationalInfo, true);
  const discountedPriceToDisplay = baseDiscountedPrice
    ? getPrice(baseDiscountedPrice, internationalInfo, true)
    : undefined;

  return {
    priceToDisplay,
    discountedPriceToDisplay: activeUserPromotionExists
      ? discountedPriceToDisplay
      : undefined,
  };
}
