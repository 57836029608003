import { useContext } from 'react';
import { StarCardOverridesContext } from '../config';
import { StarCardOverridesContextValue } from '../types';

const ContextFallback = {};

export const useStarCardOverrides =
  (): Partial<StarCardOverridesContextValue> => {
    const ctx = useContext(StarCardOverridesContext);

    return ctx ?? ContextFallback;
  };
