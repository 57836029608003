import { ActionEventSchema } from 'types/src/utils/analytics';
import analytics from 'analytics';
import { StarCardUserFields } from 'types/src/api/models/user';

export type AnalyticsArgs = {
  data: StarCardUserFields;
  eventType: 'Click' | 'Hover';
  objectDtl?: Partial<ActionEventSchema['objectDtl']>;
  positionDtl?: Partial<ActionEventSchema['positionDtl']>;
  sourceDtl?: Partial<ActionEventSchema['sourceDtl']>;
  targetDtl?: Partial<ActionEventSchema['targetDtl']>;
};

export const onEvent = ({
  data,
  eventType,
  objectDtl,
  positionDtl,
  sourceDtl,
  targetDtl,
}: AnalyticsArgs) => {
  // old analytics schema
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType,
      contents: data,
    },
    targetDtl: {
      targetPath: window.location.href,
      ...targetDtl,
    },
    objectDtl: {
      objectType: 'Card',
      containerType: 'StarCard',
      ...objectDtl,
    },
    positionDtl,
    sourceDtl,
  });
};
