import { StarCardUserFields } from 'types/src/api/models/user';
import {
  getActiveUserPromotion,
  getUserPromotionDiscountPrice,
  isUsableUserPromotion,
} from 'utils/userPromotion';

/**
 * @param data - an object containing information about the talent
 * @returns an object containing the price and remaining usages of the promotion
 */
export const getPromotionDetails = (
  data: StarCardUserFields
): {
  price: number | null;
  remaining: number | null;
} => {
  const activeUserPromotion = getActiveUserPromotion(data);
  let { price, remaining } = { price: null, remaining: null };

  if (isUsableUserPromotion(activeUserPromotion)) {
    price = getUserPromotionDiscountPrice(data.price, activeUserPromotion);
    remaining = activeUserPromotion?.usesRemaining ?? 0;
  }

  return {
    price,
    remaining,
  };
};
