import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';
import qs from 'qs';

import { getPromotionDetails } from 'domains/consumer/components/StarCard/getPromotionDetails';
import { StarCardUserFields } from 'types/src/api/models/user';
import { Box, BoxProps, Image } from 'domains/web/components';
import { Text } from 'domains/web/components/Text';
import { getFormattedPrice } from './getFormattedPrice';
import { Lightning } from './components/Lightning';
import { Star } from './components/Star';

export type StarCardProps = {
  size?: 'small' | 'medium' | 'large';
  locale: {
    countryCode: string;
    currencyCode: string;
    currencyRate?: number;
  };
  data: StarCardUserFields;
  onClick?: (event?: React.MouseEvent<HTMLAnchorElement>) => void;
  progressiveLoad?: boolean;
} & BoxProps;

export const StarCard = ({
  locale,
  data,
  size = 'large',
  onClick: onClickFromProps,
  progressiveLoad = false,
  ...rest
}: StarCardProps) => {
  const { t } = useTranslation('StarCard');

  /**
   * We can assume that a talent is new when they have 1 or fewer ratings,
   * the main reason for this is that we create a fake review when a talent adds
   * their intro video.
   */
  const isNew = data.numOfRatings <= 1;
  /**
   * Only talents that are inside the platform can have the 24hr option enabled
   */
  const is24Hr =
    data.talentSettings?.expeditedCameoSettings?.expeditedCameosEnabled &&
    !data.isExternalTalent;
  /**
   * Talent sometimes goes unavailable, and we want to keep displaying them for SEO reasons
   */
  const isUnavailable = data.temporarilyUnavailable;
  /**
   * A talent can only be at one (1) active promotion at a time
   */
  const promotion = getPromotionDetails(data);
  /**
   * Contains the ID of the charity in our DB, we only want to know if this person has or not one
   * to show a text stating that they are donating some of their profits to a charity.
   */
  const charity = data?.talentSettings?.charitySettings?.campaign?.charityId;

  const onClick = (event) => {
    // we stop the propagation to ensure that the `onClick` event from `props` runs
    event.stopPropagation();
    onClickFromProps?.(event);
  };

  /**
   * Formatted prices in the local currency
   * NOTE: if there's no promotion, the formatted price will be `Free`
   */
  const promotionPrice = getFormattedPrice({
    price: promotion.price / 100,
    locale: locale.countryCode,
    opts: {
      currency: locale.currencyCode,
      t,
      currencyRate: locale?.currencyRate,
    },
  });

  const regularPrice = getFormattedPrice({
    price: data.price / 100,
    locale: locale.countryCode,
    opts: {
      currency: locale.currencyCode,
      t,
      currencyRate: locale?.currencyRate,
    },
  });

  const linkQueryParams = {
    ...(data?.aaQueryId && { aaQueryId: data.aaQueryId }),
  };

  const queryParams = qs.stringify(linkQueryParams);

  const linkToUser = `/${data.username || data._id}${
    queryParams ? `?${queryParams}` : ''
  }`;

  return (
    <Box
      {...rest}
      width="100%"
      size={size}
      css={`
        > * {
          width: 100%;
        }

        img:hover {
          transform: translate3d(0, 0, 0) scale(1.1);
        }
      `}
    >
      <Image
        as={Link}
        borderRadius={1}
        onClick={onClick}
        imageUrlKey={data.imageUrlKey}
        alt={t('photo.alt', 'Photo of {{name}}, click to book', {
          name: data.name,
        })}
        // @ts-ignore TODO: figure out Link props with Image
        to={linkToUser}
        aspectRatio="5/6"
        height="100%"
        width="100%"
        resizerHeight="240"
        resizerWidth="200"
        progressiveLoad={progressiveLoad}
        loading="lazy"
      />
      {promotion.remaining > 0 && (
        <Text
          variant="base_s"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color="cyan.70"
          mt={2}
        >
          {t('badges.promotion', 'Only {{count}} left!', {
            count: promotion.remaining,
          })}
        </Text>
      )}
      {!promotion.remaining && charity && (
        <Text
          variant="base_s"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color="cyan.70"
          mt={2}
        >
          {t('badges.charity', 'For charity')}
        </Text>
      )}
      <Box display="flex" flexDirection="column">
        <Text
          as={Link}
          to={linkToUser}
          variant="base_s"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          mt={promotion.remaining || charity ? 1 : 2}
          onClick={onClick}
          aria-label={t('label.name', 'See more from {{name}}', {
            name: data.name,
          })}
        >
          {data.name}
        </Text>
        <CapitalisedText
          as={Link}
          to={linkToUser}
          variant="base_s"
          color="foreground.subtle"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          onClick={onClick}
          aria-label={t('label.profession', 'They are {{profession}}', {
            profession: data.profession,
          })}
        >
          {data.profession}
        </CapitalisedText>
        <Box display="flex" gap={2} my={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Star size={10} />
            {isNew && <Text variant="base_s">{t('badge.new', 'New')}</Text>}
            {!isNew && (
              <>
                <Text variant="base_s">
                  <span>{data.averageRating?.toFixed(2)}</span>
                </Text>
                <Text variant="base_s" color="foreground.subtle">
                  {`(${data.numOfRatings})`}
                </Text>
              </>
            )}
          </Box>
          {is24Hr && (
            <Box display="flex" alignItems="center" gap={1}>
              <Lightning size={10} />
              <Text variant="base_s">
                <span>{t('badge.expedited', '24hr')}</span>
              </Text>
            </Box>
          )}
        </Box>
        {!isUnavailable && (
          <Box display="flex" gap={1}>
            {Boolean(promotion.price) && data.price > 0 && (
              <Text
                as={Link}
                to={linkToUser}
                variant="base_s"
                onClick={onClick}
                aria-label={t('label.name', 'Book {{name}} for {{price}}', {
                  name: data.name,
                  price: promotionPrice,
                })}
              >
                {promotionPrice}
              </Text>
            )}
            <Text
              as={Link}
              to={linkToUser}
              variant="base_s"
              textDecoration={promotion.price ? 'line-through' : 'none'}
              color={promotion.price ? 'foreground.subtle' : ''}
              onClick={onClick}
              aria-label={t(
                'label.name',
                'Previous cameo {{price}} for {{name}}',
                { name: data.name, price: regularPrice }
              )}
            >
              {regularPrice}
            </Text>
          </Box>
        )}
        {isUnavailable && (
          <Text variant="base_s" fontWeight="bold" color="foreground.subtle">
            {t('badge.unavailable', 'Temporarily unavailable')}
          </Text>
        )}
      </Box>
    </Box>
  );
};

/**
 * Capitalised first letter using CSS, cuz we deserve it.
 */
const CapitalisedText = styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
`;
