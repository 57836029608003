import React, { ReactNode, RefObject, useEffect, useRef } from 'react';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import { useTracking } from 'react-tracking';
import analytics from 'analytics';
import { TileImpression } from '@cameo/events';
import { TileImpressionContext } from './withImpressionContext';

export const SHELVES_TITLES_TO_TRACK = Object.freeze(new Set(['Featured']));
export const PAGES_TO_TRACK = Object.freeze(new Set(['Browse']));

function shouldLogImpressionEvent(data: TileImpression) {
  return (
    SHELVES_TITLES_TO_TRACK.has(data.shelfCategory) ||
    PAGES_TO_TRACK.has(data.pageType)
  );
}

interface Props {
  tileImpression: TileImpressionContext;
  children: ReactNode;
}

function ImpressionTrackerWrapper(
  props: InjectedViewportProps &
    Props & { forwardedRef: RefObject<HTMLDivElement> }
) {
  const { tileImpression, children, inViewport, forwardedRef } = props;
  const { getTrackingData } = useTracking();
  const enterCount = useRef(0);

  useEffect(() => {
    if (inViewport) {
      enterCount.current += 1;
    }
    if (enterCount.current === 1 && inViewport && tileImpression) {
      const trackingContext: TileImpressionContext = getTrackingData();
      const fullTrackingContext = {
        ...trackingContext,
        ...tileImpression,
      };
      const impression: TileImpression = {
        eventName: 'TileImpression',
        talentOriginalChatPrice: fullTrackingContext.talentOriginalChatPrice,
        shelfTileRanking: fullTrackingContext.shelfTileRanking,
        tileId: fullTrackingContext.tileId,
        tileHorizontalCoordinate: fullTrackingContext.tileHorizontalCoordinate,
        shelfTitle: fullTrackingContext.shelfTitle,
        talentTags: fullTrackingContext.talentTags,
        tileType: fullTrackingContext.tileType,
        talentDisplayCameoPrice: fullTrackingContext.talentDisplayCameoPrice,
        pageType: fullTrackingContext.pageType,
        talentOriginalCameoPrice: fullTrackingContext.talentOriginalCameoPrice,
        shelfCategory: fullTrackingContext.shelfCategory,
        tileReadableName: fullTrackingContext.tileReadableName,
        shelfLayout: fullTrackingContext.shelfLayout,
        shelfCoordinate: fullTrackingContext.shelfCoordinate,
        talentDisplayChatPrice: fullTrackingContext.talentDisplayChatPrice,
      };

      if (shouldLogImpressionEvent(impression)) {
        analytics.logEvent(impression);
      }
    }
  }, [tileImpression, enterCount, inViewport, getTrackingData]);

  return <div ref={forwardedRef}>{children}</div>;
}

const ImpressionTracker = handleViewport(
  ImpressionTrackerWrapper,
  {},
  { disconnectOnLeave: true }
);

export const withImpressionTracker =
  (Component, getTileImpressionContext: (props?) => TileImpressionContext) =>
  (props) => {
    const tile = getTileImpressionContext(props);
    return (
      <ImpressionTracker tileImpression={tile} key={tile.tileId}>
        <Component {...props} />
      </ImpressionTracker>
    );
  };
